import React, { useState, useEffect, useRef, Fragment, useMemo, useCallback } from 'react';
import throttle from 'lodash-es/throttle';
import DockableVideo from '@/components/video/dockable-video/DockableVideo';
import { getFreewheelUserId, generateCsid } from '@/components/video/videoHelpers';
import { GammaPlayer } from '@nbcnews/gamma';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import { VideoWidgetContext } from './VideoWidgetContext';
import VideoPlaylist from './VideoPlaylist';
import SeeMore from '@/components/widgets/SeeMore';
import {
	getDataType,
	getWidgetCategory,
	getThemeClassName
} from '@/components/widgets/widgetHelpers';
import { getCookie } from '@/utils/cookies';
import { object, string, array, bool, func } from 'prop-types';
import {
	videoWidgetNoTitleClass,
	videoWidgetDesktopViewClass,
	videoWidgetThemes,
	videoWidgetDefaultTheme
} from './videoConstants';
import './VideoWidget.scss';
import '@/components/video/video-player/GammaPlayer.scss';

/**
 * @function VideoWidget
 * @description creates a video instance with a playlist
 * @param {String|null} [id] widget ID
 * @param {Array} contentList video & playlist info
 * @param {String|null} [header]
 * @param {Object|null} [headerImage]
 * @param {String|null} [widgetAdKeyword]
 * @param {String|null} [brandedTextOverride]
 * @param {String|null} [widgetAdKeyword]
 * @param {String|null} [brandingType]
 * @param {Object|null} [additionalContentLink] see more link
 * @param {Object} criteria for analytics
 * @param {Boolean} isCurated for analytics
 * @param {String|null} [position] for analytics
 * @param {String|null} [widgetTemplate] for analytics
 * @param {String|null} [mainVideo] the widget ID of the primary video widget to dock
 * @param {Function|null} [setMainVideo] sets the widget ID of the primary video widget to dock
 * @param {Boolean} [authorBylineEnabled]
 * @param {Boolean} [timestampsEnabled]
 * @param {String} [props.pageTheme]
 * @return {React.ReactElement}
 */

const VideoWidget = ({
	id,
	contentList,
	header,
	headerImage,
	widgetAdKeyword,
	brandedTextOverride,
	advertiserLogoOverride,
	brandingType,
	additionalContentLink,
	criteria,
	isCurated,
	position,
	widgetTemplate,
	authorBylineEnabled,
	timestampsEnabled,
	pageTheme,
	sectionType
}) => {
	// states to update layout on change
	const [videoNum, setVideoNum] = useState(0);
	const [playlistHeight, setPlaylistHeight] = useState(300);
	// states that don't need layout updates or are needed to avoid caching/reference issues
	const videoNumRef = useRef(0);
	const playerReady = useRef(false);
	const videoContainerEl = useRef(null);
	const playlistEl = useRef(null);
	const thumbnailClick = useRef(false);
	const autoPlay = useRef(false);
	const jwPlayerRef = useRef(null);
	const csid = useRef(null);
	const template =
		videoWidgetThemes[widgetTemplate] || videoWidgetThemes[videoWidgetDefaultTheme];

	const playlistExtraHeight = 40;
	const isSponsored = widgetAdKeyword != null;
	// filter the content list to avoid any errors with empty video info entries
	const filteredContentList = useMemo(() => {
		if (contentList) {
			return contentList.filter((obj) => obj.reference !== null);
		}
		return null;
	}, [contentList]);

	let fwReferer;
	let uid;
	const usprivacy = getCookie('usprivacy');

	if (typeof window !== 'undefined') {
		fwReferer = window.location.hostname;
		uid = getFreewheelUserId();
	}

	const seeMoreAnalyticsData = {
		'data-analytics-linkname': 'see-more',
		'data-analytics-linklocation': 'video-widget'
	};

	const thumbnailAnalyticsData = useMemo(
		() => ({
			'data-widget-type': 'video-widget',
			'data-widget-template': widgetTemplate,
			'data-widget-position': position,
			'data-widget-data-type': getDataType(criteria, isCurated),
			'data-widget-category': getWidgetCategory(criteria, isCurated)
		}),
		[criteria, isCurated, position, widgetTemplate]
	);

	// updates the state in the case of a video playlist thumbnail user click
	const handleClick = (index) => {
		// make sure the player is loaded so the data can stay in sync
		if (playerReady.current) {
			// mark as a change based on user input
			// so that we know to not autoplay the next video on release end callback
			thumbnailClick.current = true;
			// update the video info reference
			// updates what index of filteredContentList we are pointing to to fetch all video information
			setVideoNum(index);
			videoNumRef.current = index;
			autoPlay.current = true;
			jwPlayerRef.current.playlistItem(index);
			// if in mobile layout, scroll up to the top of the video
			if (window.innerWidth === videoContainerEl.current.clientWidth) {
				window.scrollTo(0, videoContainerEl.current.offsetTop);
			}
		}
	};

	// scrolls the playlist thumbnail in view;
	// used when the videos autoplay without user input
	const checkThumbnailVisibility = (index) => {
		const container = playlistEl.current;
		const parentContainer = playlistEl.current.parentElement;

		if (container.scrollHeight > container.clientHeight) {
			// check if thumbnail is in view if scrolling vertically in desktop view

			// the lowest the item can be for visiblity
			const maxItemTop = (index + 2) * 78 - container.clientHeight;
			// the highest the item can be for visibilty
			const minItemTop = index * 78;

			if (maxItemTop > container.scrollTop) {
				container.scrollTop = maxItemTop;
			} else if (minItemTop < container.scrollTop) {
				container.scrollTop = minItemTop;
			}
		} else if (parentContainer.scrollWidth > parentContainer.clientWidth) {
			// check if thumbnail is in view if scrolling horizontally in tablet view

			// the right most the item can be for visiblity
			const maxItemLeft = (index + 1) * 176 + 75 - parentContainer.clientWidth;
			// the left most the item can be for visibilty
			const minItemLeft = index * 176;

			if (maxItemLeft > parentContainer.scrollLeft) {
				parentContainer.scrollLeft = maxItemLeft;
			} else if (minItemLeft < container.scrollLeft) {
				container.scrollTop = minItemLeft;
			}
		}
	};

	// Callabcks for JW/Gamma player events
	const onPlayerReady = (player) => {
		const appMeasurement = new window.AppMeasurement('comcastegeonlineglobaldev');
		// eslint-disable-next-line no-undef
		appMeasurement.visitor = visitor;
		appMeasurement.trackingServer = 'swa.eonline.com';

		const mediaConfig = new window.VodADB.MediaConfig();
		mediaConfig.trackingServer = 'swa.eonline.com/va';
		mediaConfig.playerName = 'E! Gamma Player';
		mediaConfig.channel = 'on-domain';
		mediaConfig.appVersion = '1';
		mediaConfig.debugLogging = false;
		mediaConfig.ssl = true;

		window.VodADB.Media.configure(mediaConfig, appMeasurement);

		const tracker = window.VodADB.Media.getInstance();

		const adBreakObject = window.VodADB.Media.createAdBreakObject('preroll', 1, 0);

		const adMetaDataObject = {
			type: 'preroll',
			assetid: null
		};

		let myInterval;
		let adInterval;
		let adPosition;
		let adFlag;
		let adTimerFlag;

		const contentMetadataObject = {
			type: 'content',
			assetid: filteredContentList[videoNumRef.current]?.reference?.id || '',
			program: 'E! News Now',
			title: filteredContentList[videoNumRef.current]?.displayTitle || '',
			length: filteredContentList[videoNumRef.current]?.reference?.duration || '',
			isfullepisode: 'n',
			adloadtype: '2',
			airdate:
				filteredContentList[videoNumRef.current]?.reference?.publishDate.split('.')[0] || ''
		};

		player.on('complete', (e) => {
			tracker.trackComplete();
			const nextVideo = videoNumRef.current + 1;
			window.nSdkVideoInstance.ggPM('end', Math.round(player.getCurrentTime()));
			clearInterval(myInterval);
			if (!thumbnailClick.current && nextVideo < filteredContentList.length) {
				// if ending a video without selecting from the playlist, auto play next video
				// updates what index of filteredContentList we are pointing to to fetch all video information
				setVideoNum(nextVideo);
				videoNumRef.current = nextVideo;
				checkThumbnailVisibility(nextVideo);
			}
		});
		player.on('firstFrame', (e) => {
			adTimerFlag = false;
			const index = player.getPlaylistIndex();
			if (!adFlag) {
				const mediaObject = window.VodADB.Media.createMediaObject(
					convertedVideos[index]?.title || '',
					convertedVideos[index]?.id || '',
					convertedVideos[index]?.duration || '',
					window.VodADB.Media.StreamType.VOD || '',
					window.VodADB.Media.MediaType.Video || ''
				);
				tracker.trackSessionStart(mediaObject);
			}
			tracker.trackPlay();
			adFlag = false;
			contentMetadataObject.assetid =
				filteredContentList[videoNumRef.current]?.reference?.id || '';
			contentMetadataObject.title =
				filteredContentList[videoNumRef.current]?.displayTitle || '';
			contentMetadataObject.length =
				filteredContentList[videoNumRef.current]?.reference?.duration || '';
			contentMetadataObject.airdate =
				filteredContentList[videoNumRef.current]?.reference?.publishDate.split('.')[0] ||
				'';
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			myInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM(
					'setPlayheadPosition',
					Math.round(player.getPosition())
				);
				tracker.updatePlayhead(Math.round(player.getPosition()));
			}, 1000);
			autoPlay.current = true;
			// reset the user selected thumbnail flag
			thumbnailClick.current = false;
		});
		player.on('ready', (e) => {
			playerReady.current = true;
			jwPlayerRef.current = player;
			// eslint-disable-next-line no-undef
			ns_.StreamingAnalytics.JWPlayer(player, {
				publisherId: '603508',
				// eslint-disable-next-line no-useless-escape
				labelmapping: `ns_st_st=\"E! News-vod\", ns_st_pu=\"E!\", ns_st_ge=\"news\", c3=\"EOnline\", ns_st_ci=\"${currentVid.reference.id}\"`
			});
		});
		player.on('play', (e) => {
			if (e.oldstate === 'paused') {
				tracker.trackPlay();
				window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
				myInterval = setInterval(function () {
					window.nSdkVideoInstance.ggPM(
						'setPlayheadPosition',
						Math.round(player.getPosition())
					);
					tracker.updatePlayhead(Math.round(player.getPosition()));
				}, 1000);
			}
		});
		player.on('pause', (e) => {
			tracker.trackPause();
			clearInterval(myInterval);
		});
		player.on('adBreakStart', (e) => {
			const index = player.getPlaylistIndex();
			const mediaObject = window.VodADB.Media.createMediaObject(
				convertedVideos[index]?.title || '',
				convertedVideos[index]?.id || '',
				convertedVideos[index]?.duration || '',
				window.VodADB.Media.StreamType.VOD || '',
				window.VodADB.Media.MediaType.Video || ''
			);
			tracker.trackSessionStart(mediaObject);
			adFlag = true;
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakStart, adBreakObject);
		});
		player.on('adComplete', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdComplete);
			window.nSdkVideoInstance.ggPM('stop', Math.round(adPosition));
			clearInterval(adInterval);
		});
		player.on('adSkipped', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdSkip);
			clearInterval(adInterval);
		});
		player.on('adBreakEnd', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakComplete);
		});
		player.on('seek', (e) => {
			window.nSdkVideoInstance.ggPM('stop', Math.round(player.getCurrentTime()));
		});
		player.on('adPause', (e) => {
			clearInterval(adInterval);
		});
		player.on('adTime', (e) => {
			adPosition = e.position;
			if (adTimerFlag !== true) {
				tracker.updatePlayhead(Math.round(adPosition));
				adTimerFlag = true;
				const adMetadata = {};
				adMetadata[window.VodADB.Media.AdMetadataKeys.Advertiser] = e.client;
				const adObject = window.VodADB.Media.createAdObject('preroll', e.id, 1, e.duration);
				tracker.trackEvent(window.VodADB.Media.Event.AdStart, adObject, adMetadata);
			}
		});
		player.on('adPlay', (e) => {
			contentMetadataObject.assetid =
				filteredContentList[videoNumRef.current]?.reference?.id || '';
			contentMetadataObject.title =
				filteredContentList[videoNumRef.current]?.displayTitle || '';
			contentMetadataObject.length =
				filteredContentList[videoNumRef.current]?.reference?.duration || '';
			contentMetadataObject.airdate =
				filteredContentList[videoNumRef.current]?.reference?.publishDate.split('.')[0] ||
				'';
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			adMetaDataObject.assetid = e.id;
			window.nSdkVideoInstance.ggPM('loadMetadata', adMetaDataObject);
			adInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM('setPlayheadPosition', Math.round(adPosition));
				tracker.updatePlayhead(Math.round(adPosition));
			}, 1000);
		});
		player.on('error', (e) => {
			tracker.trackError(e);
			// JW error codes for A manifest request returned with an HTTP status indicating failure.
			window.nSdkVideoInstance.ggPM('end', Math.round(player.getCurrentTime()));
			window.nSdkVideoInstance.ggPM('stop', Math.round(player.getCurrentTime()));
			if (e.code > 232399 && e.code <= 232600) {
				convertedVideos.forEach((el) => {
					el.file = el.file.split('formats')[0] + 'formats=MPEG4';
					el.type = 'mp4';
				});
				player.load(convertedVideos);
				player.playlistItem(videoNumRef.current);
			}
		});
	};
	// PDK video event listener callbacks
	const videoPlayerCallbacks = {
		// new video start event
		onReleaseStart: (e) => {
			autoPlay.current = true;
			// reset the user selected thumbnail flag
			thumbnailClick.current = false;
		},
		// video end event - fire when a whole video release, and not just an ad, ends
		onReleaseEnd: (e) => {
			const nextVideo = videoNumRef.current + 1;
			if (!thumbnailClick.current && nextVideo < filteredContentList.length) {
				// if ending a video without selecting from the playlist, auto play next video
				// updates what index of filteredContentList we are pointing to to fetch all video information
				setVideoNum(nextVideo);
				videoNumRef.current = nextVideo;
				checkThumbnailVisibility(nextVideo);
			}
		},
		// fires once when the player object is set up and the video controllers are available
		onPlayerReady: (e) => {
			playerReady.current = true;
		}
	};

	const updateLayout = useCallback(() => {
		if (videoContainerEl.current !== null) {
			if (!template.isStacked && window.innerWidth >= 1024) {
				// for desktop sidebar view only
				// playlist container updates height when video height changes
				setPlaylistHeight(videoContainerEl.current.clientHeight + playlistExtraHeight);
			} else {
				// unset height in mobile and tablet
				setPlaylistHeight('auto');
			}
		}
	}, [template.isStacked]);

	useEffect(() => {
		const resizeListener = throttle(updateLayout, 50);
		window.addEventListener('resize', resizeListener);
		return () => window.removeEventListener('resize', resizeListener);
	}, [updateLayout]);

	useEffect(() => {
		updateLayout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoContainerEl]);

	useEffect(() => {
		csid.current = generateCsid();
	}, []);

	if (!contentList) return null;

	const context = {
		list: filteredContentList,
		videoNum,
		playlistHeight,
		handleClick,
		playlistEl,
		isCurated,
		isSponsored,
		analyticsData: thumbnailAnalyticsData,
		authorBylineEnabled,
		timestampsEnabled
	};

	const currentVid = filteredContentList[videoNum];
	const convertedVideos = filteredContentList
		? filteredContentList.map((video) => {
				return {
					file:
						video.reference.videoUri +
						'/?mbr=true&format=redirect&manifest=m3u&format=redirect&Tracking=true&Embedded=true&formats=M3U',
					title: video.displayTitle,
					type: 'hls',
					id: video?.reference?.id || '',
					fwassetid: `eonline-${video?.reference?.id}`,
					duration: video?.reference?.duration || 0,
					image: video?.reference?.hdThumbnail?.uri || video?.displayImage?.uri,
					tracks: [
						{
							kind: 'captions',
							file: video.reference.webVttUri,
							label: 'English'
						}
					]
				};
		  })
		: null;

	return (
		<Fragment>
			{filteredContentList.length > 0 && (
				<section
					data-hook="video-widget-section"
					data-tb-region={`widget__video ${widgetTemplate} ${position}`}
					className={`widget widget__video widget--with-border ${
						getThemeClassName(pageTheme) || template.themeClass
					} ${!template.isStacked ? videoWidgetDesktopViewClass : ''} ${
						header || headerImage ? '' : videoWidgetNoTitleClass
					}`}
				>
					<WidgetHeader
						title={header}
						thumbnail={headerImage}
						widgetAdKeyword={widgetAdKeyword}
						brandedTextOverride={brandedTextOverride}
						advertiserLogoOverride={advertiserLogoOverride}
						brandingType={brandingType}
					/>

					<div className="video-widget columns is-gapless">
						<div
							className={`video-widget__main-video column ${
								!template.isStacked ? 'is-two-thirds-desktop' : ''
							} is-full-tablet is-full-mobile`}
							data-tb-region-item
						>
							<div className="video-widget__video" ref={videoContainerEl}>
								<DockableVideo
									title={currentVid.displayTitle}
									playerId={`widget-video-${id}`}
									playerCallbacks={videoPlayerCallbacks}
								>
									<GammaPlayer
										className="gamma-widget"
										jwPlayerLibraryUrl="https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.28.0/jwplayer.js"
										jwPlayerLibraryKey="+9o3ihbMIU8/ixFry35xlHnkQ9tikKg9TU0io1QbWXfpeR0q"
										floating={{
											dismissible: true
										}}
										skin={{
											name: 'myskin'
										}}
										playlist={convertedVideos}
										related={{
											autoplaytimer: 0
										}}
										width="100%"
										height="100%"
										mute={false}
										jwPlayerRef={onPlayerReady}
										autostart={autoPlay.current}
										advertising={{
											client: 'freewheel',
											freewheel: {
												networkid: 169843,
												serverid: 'https://29773.v.fwmrm.net/ad/g/1',
												profileid: '169843:nbcu_web_jwp_cs_moat_https',
												sectionid: csid.current,
												adManagerUrl: `https://mssl.fwmrm.net/libs/adm/6.51.0/AdManager.js`,
												afid: '127497404',
												sfid: '586754',
												vcid: uid,
												capabilities: {
													flag: {
														dtrd: 'on',
														amcb: 'on',
														sbid: 'on'
													}
												},
												custom: {
													_fw_vcid2: uid,
													_fw_h_referer: fwReferer,
													_fw_player_width: 890,
													_fw_player_height: 498,
													_fw_us_privacy: usprivacy
												}
											},
											adscheduleid: '12345',
											schedule: {
												adbreak: {
													offset: 'pre',
													tag: 'placeholder_preroll'
												}
											},
											vpaidcontrols: true,
											skipoffset: 3
										}}
									/>
								</DockableVideo>
							</div>
							<div className="video-widget__title text--md">
								<h3>
									<a href={currentVid.reference.uri}>{currentVid.displayTitle}</a>
								</h3>
							</div>
						</div>
						<div
							className={`column ${
								!template.isStacked ? 'is-one-third-desktop' : ''
							} is-full-tablet is-full-mobile`}
						>
							<VideoWidgetContext.Provider value={context}>
								<VideoPlaylist />
							</VideoWidgetContext.Provider>
							{additionalContentLink != null && (
								<div className="video-widget__see-more">
									<SeeMore
										link={additionalContentLink.reference.uri}
										analyticsData={seeMoreAnalyticsData}
									/>
								</div>
							)}
						</div>
					</div>
				</section>
			)}
		</Fragment>
	);
};

VideoWidget.propTypes = {
	id: string,
	contentList: array.isRequired,
	header: string,
	headerImage: object,
	widgetAdKeyword: string,
	brandedTextOverride: string,
	advertiserLogoOverride: string,
	brandingType: string,
	additionalContentLink: object,
	criteria: object.isRequired,
	isCurated: bool.isRequired,
	position: string,
	widgetTemplate: string,
	mainVideo: string,
	setMainVideo: func,
	authorBylineEnabled: bool,
	timestampsEnabled: bool,
	pageTheme: string,
	sectionType: string
};

VideoWidget.displayName = 'VideoWidget';

export default VideoWidget;
